import { NavController,ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ParlorService } from '../parlor.service';
import { RestService } from '../../rest.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../login.service';
import { Storage } from '@ionic/storage';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';

import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-editadvance',
  templateUrl: './editadvance.page.html',
  styleUrls: ['./editadvance.page.scss'],
})
export class EditadvancePage implements OnInit {
  public form: FormGroup;
  staff: AbstractControl;
  Advance_Date: AbstractControl;
  Advance_Amount: AbstractControl;
  Advance_Reason: AbstractControl;
  validation_msg: any;

  staffrequest = {
    Parlor_Id: "",

  }
  load: any;
  abc: any
  stafflist: any;
  stafflistresponse: any;

  staffadvancesaverequest = {
    upStaffAdvance: {
      Parlor_Id: '',
      Staff_Id: '',
      Advance_Date: '',
      Advance_Amount: '',
      Advance_Reason: '',
      Staff_Advance_Id:''
    }

  }
  doad: any;
  saveadvanceresponse:any;
  staffedit:any;
  staff_name:any;
  constructor(public modalController: ModalController,private loginervice: LoginService, public menuCtrl: MenuController, public storage: Storage, private fb: FormBuilder,
    private loader: LoadingController,
   private toast: ToastService, public datepipe: DatePipe, private rest: RestService, private navCtrl: NavController, private parlor: ParlorService) {


    this.validation_msg = {

      'staff': [

     
        { type: 'required', message: ' Please select Staff' }

      ],
      'Advance_Date': [

      
        { type: 'required', message: ' Please select Date.' }

      ],
      'Advance_Amount': [

        { type: 'pattern', message: 'Advance Amount should be of numbers.' },
        { type: 'required', message: ' Advance Amount Required.' }

      ],
      'Advance_Reason': [

       
        { type: 'required', message: 'Please Enter Reason' }

      ],
    }

    this.form = this.fb.group({

      staff: ['', Validators.compose([Validators.required])],

      Advance_Date: ['', Validators.compose([Validators.required])],
      Advance_Amount: ['', Validators.compose([Validators.required,Validators.pattern("[0-9]+(\.[0-9][0-9]?)?")]),],
      Advance_Reason: ['', Validators.compose([Validators.required])]

    })

    this.staff = this.form.controls['staff'];
    this.Advance_Date = this.form.controls['Advance_Date'];
    this.Advance_Amount = this.form.controls['Advance_Amount'];
    this.Advance_Reason = this.form.controls['Advance_Reason']
  }

  ngOnInit() {
  }
  ionViewDidEnter() {

    this.loginervice.load();
    this.staffrequest.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.staffadvancesaverequest.upStaffAdvance.Parlor_Id = this.loginervice.login.Parlor_Id;
    // this.commissionEnteryrequest.newStaffCommission.Parlor_Id = this.loginervice.login.Parlor_Id;
    //this.load.present1();
  this.storage.get('edit_staff').then((val) => {
this.staffedit=val;
this.staff_name=this.staffedit.Staff_Name;
this.staffadvancesaverequest.upStaffAdvance.Staff_Advance_Id=this.staffedit.Staff_Advance_Id;
this.staffadvancesaverequest.upStaffAdvance.Parlor_Id=this.staffedit.Parlor_Id;
this.staffadvancesaverequest.upStaffAdvance.Staff_Id=this.staffedit.Staff_Id;
this.staffadvancesaverequest.upStaffAdvance.Advance_Date=this.staffedit.Advance_Date;
this.staffadvancesaverequest.upStaffAdvance.Advance_Amount=this.staffedit.Advance_Amount;
this.staffadvancesaverequest.upStaffAdvance.Advance_Reason=this.staffedit.Advance_Reason;
this.doad=this.staffedit.Advance_Date2;

  })
  }

 async addAdvance() {
    this.staffadvancesaverequest.upStaffAdvance.Advance_Date=this.datepipe.transform(this.doad,'dd/MM/yyyy');
    if (this.form.valid) {
      this.load= await this.loader.create({
        spinner: 'bubbles',
        message:'wait a moment'
      })
this.load.present();

this.rest.UpdateStaffAdvance(this.staffadvancesaverequest).then(data=>{

this.saveadvanceresponse=data;
if(this.saveadvanceresponse.UpdateStaffAdvanceResult.Result="Success")
{
  this.toast.show("Advance updated Succssfuly");
  this.staffadvancesaverequest.upStaffAdvance.Advance_Date='';
  this.staffadvancesaverequest.upStaffAdvance.Advance_Amount=''
  this.staffadvancesaverequest.upStaffAdvance.Advance_Reason=''
  this.doad=''
  this.load.dismiss();
  this.navCtrl.navigateBack('root');
    this.navCtrl.navigateRoot('');
    this.modalController.dismiss();
}
else{
  this.load.dismiss();
  this.toast.show("Advance Not updated Succssfuly");
}
})
    } else {
      this.toast.show("Please Enter Proper Data");
    }

  }

  ionViewWillLeave(){
    this.load.dismiss();
  }

  
  close()
  {
    this.modalController.dismiss();
  }
}


