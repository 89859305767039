import { Injectable } from '@angular/core';

//import { Firebase } from '@ionic-native/firebase/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor( 
  
    private platform: Platform) { }

    // Get permission from the user
    
}
