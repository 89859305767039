import { Injectable } from '@angular/core';
//import { TostService } from './tost.service';


import { Storage } from '@ionic/storage';
//import { Http, Response } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class ParlorService {
  private USER_KEY: string = 'parlor';
  parlor: any = {};
  _readyPromise: Promise<any>;
  constructor(public storage: Storage,) {
    this.load();

   }
   _loggedIn(user){
     console.log(user);
    //this.parlor.Username = user.Username;
    this.parlor.Record_Id = user.Record_Id;
   // this.parlor.Farmername=user.Farmername;
    this.parlor.logged_in= 1;
    //console.log(this,user);
    this.save();
    console.log(this.parlor.Record_Id);
//this.events.publish('user:login', {tabIndex: index});
    return this.parlor;
  }
  
  
  save(){
    return this.storage.set(this.USER_KEY, this.parlor);
  }
  
  load() {
    console.log(this.parlor.Record_Id);
    return this.storage.get(this.USER_KEY).then((val) => {
      if (val)
this._loggedIn(val);
console.log(this.parlor.Record_Id);
    });
  }
  
  logout(){
    this.parlor = {};
    return this.storage.remove(this.USER_KEY).then(() => {
      //this.toast.show("Logged Out Successfully.");
    });
  }
  


}
