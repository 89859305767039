import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-supplierdetails',
  templateUrl: './supplierdetails.page.html',
  styleUrls: ['./supplierdetails.page.scss'],
})
export class SupplierdetailsPage implements OnInit {
  supplier:any;
  Address:any;
  PAN_No:any;
  Mobile_No:any;
  GST_No:any
  Supplier_Name:any;
  Aadhar_No:any;
  supplieredit:any;
  constructor( public modalController: ModalController,public datepipe: DatePipe,public load :LaodlingService,private toast:ToastService, private storage:Storage, private parlor:ParlorService,private navCtrl: NavController,private rest:RestService) {
    this.getdetail();
    
    } 
  ngOnInit() {
  }
  getdetail(){
    this.load.present1();
    return this.storage.get('supplier_detail').then((val) => {
      this.load.present1();
    this.supplier=val;
    this.Supplier_Name=this.supplier.Supplier_Name;
 this.Address=this.supplier.Address;
 this. PAN_No=this.supplier.PAN_No;
 this. Mobile_No=this.supplier.Mobile_No;
 this. GST_No=this.supplier.GST_No;
 this. Aadhar_No=this.supplier.Aadhar_No;
    console.log("supplier:" ,this.supplier);
    })
  }
edit()
{
  this.storage.set('Supplier_edit',1)
  this.modalController.dismiss();
  this.navCtrl.navigateForward('/supplier-master');


}
close()
{
  this.modalController.dismiss();
}
}
