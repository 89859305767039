import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LaodlingService {
  load: any;
isloading=false;
  constructor(private loader: LoadingController) { 

  }
  async pre(){
    this.load= await this.loader.create({
      spinner: 'bubbles',
      message:'wait a moment'
    })
    this.load.present();
  }

 async present1() {
  // console.log(this.isShowing());
   if(this.isloading==true)
   {
    //console.log("I am in if");
    this.load.dismiss();
    this.isloading=false; 
   }else
   {
    //console.log( "i am in else");
      this.load= await this.loader.create({
        spinner: 'bubbles',
        message:'wait a moment',
        translucent: true,
        duration: 2500,
      
      })
      this.isloading=true; 
     // console.log(this.isloading);
        this.load.present();
     //this.isloading=true; 
   }
  }
disiss()
{
  this.load.dismiss();
}
  isShowing(){
    return this.isloading;
  }
}
