import {Component, Input, ViewEncapsulation} from '@angular/core';
import {SideMenuOption} from '../side-menu-option';
import { NavController } from '@ionic/angular';
@Component({
    selector: 'custom-side-menu',
    templateUrl: './custom-side-menu.component.html',
    styleUrls: ['./custom-side-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomSideMenuComponent {
    optionHeight = 45;
    paddingLeft = 16;
    @Input() menuList: Array<SideMenuOption>;

    constructor(private navCtrl:NavController ) {
    }

    toggle(item) {
        item.expanded = !item.expanded;
    }

    continue(page) {
      // this.navCtrl.navigateForward(page);
      // this.router.navigate([page]);
      this.navCtrl.navigateForward(page);
  
    }
}
