import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private USER_KEY: string = 'notification';
  notification: any =[];
  temp:any=[];
  notifications:any=[];
  _readyPromise: Promise<any>;
  constructor(public storage: Storage) { 
     this.load();
  }

 addnotification(data){
  return this.storage.get(this.USER_KEY).then((val) => {
    if (val!=null)
  {
this.temp=val;
this.temp.push(data);
this.notification=this.temp;
this.temp=[];
this.storage.set(this.USER_KEY,this.notification);
  }else
  {
    this.temp=[];
    this.temp.push(data)
    this.notification=this.temp;
    this.temp=[];
    this.storage.set(this.USER_KEY,this.notification);
  }
  })

 }
load()
{
  this.notifications=[];
  return this.storage.get(this.USER_KEY).then((val) => {
    if (val!=null)
  {
    this.notifications=val;
  }
  else
  {
    this.notifications=[];
  }
  
})

}


clear()
{
  this.notifications=[];
  return this.storage.remove(this.USER_KEY).then(() => {
    //this.toast.show("Logged Out Successfully.");
  });
}
}
