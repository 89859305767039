import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-collectiondetail',
  templateUrl: './collectiondetail.page.html',
  styleUrls: ['./collectiondetail.page.scss'],
})
export class CollectiondetailPage implements OnInit {
  bill:any;
  clientname:any;
  mobileno:any;
  dob:any;
  discount:any;
  netamount:any;
  feedbackData:any;
  isShowFeedback:boolean;
  services:any[]=[];
  paymentModeCollection:any[]=[];
  total_amount:any;
  feedback_Content:any;                           
  rating:any;
  feedback_QA_Data:any;
  feedback_QA_Array:any[]=[];
  remark:any;
  ambience:any;
  staff:any;
  services_:any;
  PaymentMode="";
  constructor( public modalController: ModalController,public datepipe: DatePipe,public load :LaodlingService,private toast:ToastService, private storage:Storage, private parlor:ParlorService,private navCtrl: NavController,private rest:RestService) {
    this.getdetail();
    
    }
  

  ngOnInit() {
  }
  getdetail(){
    this.load.present1();
    this.isShowFeedback=false;
    return this.storage.get('collection_detail').then((val) => {
      
  this.bill=val;
  console.log(this.bill);
  this.clientname=this.bill.Client_Data.Client_Name;
  this.mobileno=this.bill.Client_Data.Mobile_No;
  this.dob=this.bill.Bill_Date;
  this.services=this.bill.Bill_Service_List;
  this.total_amount=this.bill.Total_Amount;
  this.discount=this.bill.Discount_Amount;
  this.netamount=this.bill.Net_Amount;
  this.PaymentMode=this.bill.Payment_Mode_Name;
  this.remark=this.bill.Remark;
  this.feedbackData=this.bill.Feedback_Data;
  if(this.feedbackData!=null)
  {
      this.isShowFeedback=true;
      this.feedback_Content=this.bill.Feedback_Data.Feedback_Content;                          
      this.rating=this.bill.Feedback_Data.Rating;
      this.feedback_QA_Array=this.bill.Feedback_Data.Feedback_QA_Data.split(',')
      console.log(this.feedback_QA_Array);
  }
  else
  {
    this.isShowFeedback=false;
  }

  if(this.bill.Client_Payment_Mode_List.length>0)
  {
    this.paymentModeCollection=[];
    this.paymentModeCollection=this.bill.Client_Payment_Mode_List;
  }
  
  console.log(this.bill);
  this.load.present1();
    })
  }


  close()
  {
    this.modalController.dismiss();
  }
}
