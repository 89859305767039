import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private USER_KEY: string = 'login';
  login: any = {};
  StorageVal: any;
  parlorName:any;
  _readyPromise: Promise<any>;
  constructor(public storage: Storage) {
    this.load();


  }
  _loggedSignIn(user) {
    //console.log("Show User Data.........................................");
    //console.log(user);
//debugger;
    //this.parlor.Username = user.Username;
    this.StorageVal = user.is_Banner_Exist;

    this.login.IsBanner = this.StorageVal;
    this.login.Parlor_Id = user.Parlor_Id;
    this.login.Parlor_Name = user.Parlor_Name;
    this.login.Contact_Person = user.Contact_Person;
    this.login.Mobile_No = user.Mobile_No;
    this.login.Registration_Date = user.Registration_Date;
    this.login.Subscribed_SMS_Count = user.Subscribed_SMS_Count;
    this.login.Used_SMS_Count = user.Used_SMS_Count;
    this.login.Subscription_End_Date = user.Subscription_End_Date;
    this.login.Parlor_Code = user.Parlor_Code;
    this.login.Address = user.Address;
    this.login.GPSLink = user.GPSLink;
    this.parlorName=user.Parlor_Name;
    if(user.Login_Flag=== "Parlour" )
    {
      this.login.planId=user.Plan_Id;

    }
    else
    {
      this.login.planId=user.Parlor_Data.Plan_Id;

    }
    this.login.isSubscription=user.Is_Subscription_Ok;
    this.login.bookingLink=user.OnlineBookingLink;
    this.login.isBanner=user.is_Banner_Exist;
    this.login.LoginFlag=user.Login_Flag;
    this.login.Username=user.Username;
    this.login.Password=user.Password;

    this.login.Photo_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_profile_photos/";
    //console.log("Image :")
    //console.log(this.login.Is_image);
    if (this.StorageVal) {
      this.login.bannerImage_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner" + user.Parlor_Id + ".jpg";
    }
    else {
      this.login.bannerImage_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner.jpg";
    }
    //this.login.parlorInfo = "*" + this.parlorName + "*\n" + user.Mobile_No;

    // this.parlor.Farmername=user.Farmername;
    this.login.logged_in = 1;
    //console.log(this,user);

    // Appointment Confirm Message

    this.login.appointmentMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No 
      + ",\nAddress - #Address#" + "\nHi " + "#ClientName#" + ",\n"
      + "Your appointment is confirmed for " + "#services#"
      + " services.\nYour appointment is scheduled on (" + "#Appointment_Date#" + ", "
      + "#Appointment_Time#" + ") Thank You."
      + "#OnlineBookingLink#";

    this.login.appointmentReminderMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No + ",\nAddress - #Address#"
      + "\nDear " + "#ClientName#" + ",\n"
      + "We are kindly waiting to show intense beauty with timely services, Your appointment time is today at ("
      + "#Appointment_Time#" + ") Thank You."
      + "#OnlineBookingLink#";

      this.login.appointmentPostponeMeg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ", Your appointment is postponed on (#PostponeDate#, #PostponeTime#). Thank you,"
      + "#OnlineBookingLink#";

      this.login.appointmentCancelMeg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ", Your appointment request is cancelled."
      + "#OnlineBookingLink#";

    this.login.billEntryMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ",\nThank you for showing your interest to accomplish "
      + "#services#" + " services. Your bill amount is Rs." + "#NetAmount#"
      + "/-. We are grateful to see a generous client again."
      + "#OnlineBookingLink#";

    
    this.login.enquiryReminder = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ", this is a reminder about your enquiry on #enquiryDate# about #serviceItem#"
      + ". Looking forward to seeing you."
      + "#OnlineBookingLink#";

    this.login.enquiryMessage = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ", We are thankful for enquiry and we will be providing our best guidance according to your needs!"
      + "#OnlineBookingLink#";

    this.login.feedbackMsg =
      "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + "\n, We are happy with your gracious presence of feedback."
      + "#OnlineBookingLink#";

    this.login.birthdayMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ",\nWe wish you a very happy birthday, we pray on this day, which bring glory in your life to fulfill your beautiful dreams.\n"
      + "#OnlineBookingLink#";

    this.login.anniversaryMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ",\nWe wish you a happy marriage anniversary, we pray on this day to fulfill your life with all pleasure."
      + "#OnlineBookingLink#";

    this.login.customSMSMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\n*#offerTitle#*"
      + "\n#offerBody#."
      + "\n#OnlineBookingLink#";

      this.login.offerPhotoMessage = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - "+ this.login.Address
      + "\n*#offerTitle#*"
      + "\nFor more details click on below link"
      + "\n#offerLink#";
    // this.parlor.Farmername=user.Farmername;
    //console.log(this,user);
    this.save();
    //console.log(this.login.Parlor_Id);
    //this.events.publish('user:login', {tabIndex: index});
    return this.login;
  }

  _loggedIn(user) {
    //console.log("Show User Data.........................................");
    //console.log(user);

    //this.parlor.Username = user.Username;
   // debugger;
    this.login.IsBanner = user.IsBanner;
    this.StorageVal = user.IsBanner;
    this.login.Parlor_Id = user.Parlor_Id;
    this.login.Parlor_Name = user.Parlor_Name;
    this.login.Contact_Person = user.Contact_Person;
    this.login.Mobile_No = user.Mobile_No;
    this.login.Registration_Date = user.Registration_Date;
    this.login.Subscribed_SMS_Count = user.Subscribed_SMS_Count;
    this.login.Used_SMS_Count = user.Used_SMS_Count;
    this.login.Subscription_End_Date = user.Subscription_End_Date;
    this.login.Parlor_Code = user.Parlor_Code;
    this.login.Address = user.Address;
    this.login.GPSLink = user.GPSLink;
    this.login.LoginFlag=user.LoginFlag;
    this.login.planId=user.planId;
    this.login.isSubscription=user.isSubscription;
    this.login.bookingLink=user.bookingLink;
    this.login.isBanner=user.isBanner;
    this.login.Username=user.Username;
    this.login.Password=user.Password;
    this.parlorName=user.Parlor_Name;
    this.login.Photo_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_profile_photos/";
    //console.log("Image :")
    //console.log(this.login.Is_image);
    if (this.StorageVal) {
      this.login.bannerImage_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner" + user.Parlor_Id + ".jpg";
    }
    else {
      this.login.bannerImage_URL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner.jpg";
    }
    //this.login.parlorInfo = "*" + user.Parlor_Name.replace(/\s/g, "") + "*\n" + user.Mobile_No;

    // this.parlor.Farmername=user.Farmername;
    this.login.logged_in = 1;
    //console.log(this,user);

    // Appointment Confirm Message

    this.login.appointmentMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No 
      + ",\nAddress - #Address#" + "\nHi " + "#ClientName#" + ",\n"
      + "Your appointment is confirmed for " + "#services#"
      + " services.\nYour appointment is scheduled on (" + "#Appointment_Date#" + ", "
      + "#Appointment_Time#" + ") Thank You."
      + "#OnlineBookingLink#";

    this.login.appointmentReminderMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No + ",\nAddress - #Address#"
      + "\nDear " + "#ClientName#" + ",\n"
      + "We are kindly waiting to show intense beauty with timely services, Your appointment time is today at ("
      + "#Appointment_Time#" + ") Thank You."
      + "#OnlineBookingLink#";

      this.login.appointmentPostponeMeg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ", Your appointment is postponed on (#PostponeDate#, #PostponeTime#). Thank you,"
      + "#OnlineBookingLink#";

      this.login.appointmentCancelMeg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ", Your appointment request is cancelled."
      + "#OnlineBookingLink#";

    this.login.billEntryMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No + ",\nAddress - #Address#"
      + "\nDear " + "#clientName#"
      + ",\nThank you for showing your interest to accomplish "
      + "#services#" + " services. Your bill amount is Rs." + "#NetAmount#"
      + "/-. We are grateful to see a generous client again."
      + "#OnlineBookingLink#";

    
    this.login.enquiryReminder = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ", this is a reminder about your enquiry on #enquiryDate# about #serviceItem#"
      + ". Looking forward to seeing you."
      + "#OnlineBookingLink#";

    this.login.enquiryMessage = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ", We are thankful for enquiry and we will be providing our best guidance according to your needs!"
      + "#OnlineBookingLink#";

    this.login.feedbackMsg =
      "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + "\n, We are happy with your gracious presence of feedback."
      + "#OnlineBookingLink#";

    this.login.birthdayMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ",\nWe wish you a very happy birthday, we pray on this day, which bring glory in your life to fulfill your beautiful dreams.\n"
      + "#OnlineBookingLink#";

    this.login.anniversaryMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#" + "\nDear #clientName#"
      + ",\nWe wish you a happy marriage anniversary, we pray on this day to fulfill your life with all pleasure."
      + "#OnlineBookingLink#";

    this.login.customSMSMsg = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - #Address#"
      + "\n*#offerTitle#*"
      + "\n#offerBody#."
      + "\n#OnlineBookingLink#";

      this.login.offerPhotoMessage = "*" + user.Parlor_Name + "*\n" + user.Mobile_No
      + ",\nAddress - "+ this.login.Address
      + "\n*#offerTitle#*"
      + "\nFor more details click on below link"
      + "\n#offerLink#";

    this.save();
    //console.log(this.login.Parlor_Id);
    //this.events.publish('user:login', {tabIndex: index});
    return this.login;
  }

  save() {
    //debugger;
    return this.storage.set(this.USER_KEY, this.login);
  }

  load() {
   // console.log(this.login.Parlor_Id);
    return this.storage.get(this.USER_KEY).then((val) => {
      if (val) {
        //console.log("login Service Load : ")
        //console.log(val);
        this._loggedIn(val);
        //console.log(this.login.Parlor_Id);
      }

    });
  }

  logout() {
    this.login = {};
    this.login.logged_in = 0;
    return this.storage.remove(this.USER_KEY).then(() => {
      //this.toast.show("Logged Out Successfully.");
    });

  }



}
