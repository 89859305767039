import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { LoadingController } from '@ionic/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-analysis-services-details',
  templateUrl: './analysis-services-details.page.html',
  styleUrls: ['./analysis-services-details.page.scss'],
})
export class AnalysisServicesDetailsPage implements OnInit {


  requestServiceDetail = {
    Bill_Entry_Id: 0,
  }

 serviceDetailResponse: any;



  storageItem:any;
  billNumber:any;
  billDate:any;
  billTime:any;
  totalAmount:any;
  discountAmount:any;
  netAmount:any;
  serviceListData:any[]=[];
  load:any;

  constructor( 
    public modalController: ModalController,
    public datepipe: DatePipe,
    public loadingservices :LaodlingService,
    public loadingController: LoadingController,
    private toast:ToastService, 
    private storage:Storage, 
    private parlor:ParlorService,
    private navCtrl: NavController,
    private restService:RestService) {
    }
  

    ngOnInit() {
      this.storage.get('serviceDetail').then((val) => {
          this.storageItem=val;
          console.log(this.storageItem);
          this.billNumber=this.storageItem.Bill_Entry_Id;
          this.requestServiceDetail.Bill_Entry_Id=this.billNumber;
          this.getClientServicesData();
      });
    }

  async getClientServicesData() {
    //debugger;

      this.serviceListData=[];
      this.load = await this.loadingController.create({
        spinner: 'bubbles',
        message: 'wait a moment'
      })
      this.load.present();
      this.restService.getClientServicesDetails(this.requestServiceDetail).then(data => {
          this.serviceDetailResponse = data;
          console.log(this.serviceDetailResponse);
            this.serviceListData=this.serviceDetailResponse.getBillByIDResult.Bill_Service_List;
            this.billDate=this.serviceDetailResponse.getBillByIDResult.Bill_Date;
            this.billTime=this.serviceDetailResponse.getBillByIDResult.Bill_Service_List;
            this.totalAmount=this.serviceDetailResponse.getBillByIDResult.Total_Amount;
            this.discountAmount=this.serviceDetailResponse.getBillByIDResult.Discount_Amount;
            this.netAmount=this.serviceDetailResponse.getBillByIDResult.Net_Amount;
            this.load.dismiss();
      });
  }


  close()
  {
    this.modalController.dismiss();
  }
}
