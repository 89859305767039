import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { LoginService } from '../login.service';
import { RestService } from '../../rest.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';
import { MenuController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import * as moment from "moment";


@Component({
  selector: 'app-basic-staff-attendance-update',
  templateUrl: './basic-staff-attendance-update.page.html',
  styleUrls: ['./basic-staff-attendance-update.page.scss'],
})
export class BasicStaffAttendanceUpdatePage implements OnInit {

  staffAttendanceUpdateRequest = {
    upStaffAttendance: {
      Staff_Attendance_Id: 0,
      Attendance_Date: "",
      Parlor_Id: 0,
      Staff_Id: 0,
      Presenty_Status: "",
      In_Time: "",
      Out_Time: "",
      Working_Hours: 0,
    }

  }
  staffAttendanceNewResponse: any;

  staffAttendanceNewRequest = {
    newStaffAttendance: {
      Staff_Attendance_Id: 0,
      Attendance_Date: "",
      Parlor_Id: 0,
      Staff_Id: 0,
      Presenty_Status: "",
      In_Time: "",
      Out_Time: "",
      Working_Hours: 0,
    }

  }
  staffAttendanceUpdateResponse: any;

  inTime: any;
  outTime: any;
  workingHours: any;
  load: any;
  staffName: any;

  in_time: any;
  out_time: any;


  // For Validation
  isInTime: any;
  isOutTime: any;
  isTimeValid: boolean = false;

  // validation Message

  constructor(private loginService: LoginService,
    public modalController: ModalController,
    public navController: NavController,
    public datepipe: DatePipe,
    public loadingService: LaodlingService,
    public loadingController: LoadingController,
    private toast: ToastService,
    private storage: Storage,
    public menuController: MenuController,
    private restService: RestService,) { }

  ngOnInit() {
    this.storage.get('staffAttendanceData').then((val) => {
      //debugger;
      if (val) {
        console.log("staff Attendance Data : ");
        console.log(val);

        this.staffName = val["Staff_Name"]
        if (val["Staff_Attendance_Id"] == 0) {
          this.staffAttendanceNewRequest.newStaffAttendance.Staff_Attendance_Id = val["Staff_Attendance_Id"];
          this.staffAttendanceNewRequest.newStaffAttendance.Attendance_Date = val["Attendance_Date"];
          this.staffAttendanceNewRequest.newStaffAttendance.Parlor_Id = val["Parlor_Id"];
          this.staffAttendanceNewRequest.newStaffAttendance.Staff_Id = val["Staff_Id"];
          this.staffAttendanceNewRequest.newStaffAttendance.Presenty_Status = val["Presenty_Status"];
          this.in_time = this.convertTime(val["In_Time"]==""?null:val["In_Time"]);
          this.out_time = this.convertTime(val["Out_Time"]==""?null:val["Out_Time"]);
        }
        else {
          this.staffAttendanceUpdateRequest.upStaffAttendance.Staff_Attendance_Id = val["Staff_Attendance_Id"];
          this.staffAttendanceUpdateRequest.upStaffAttendance.Attendance_Date = val["Attendance_Date"];
          this.staffAttendanceUpdateRequest.upStaffAttendance.Parlor_Id = val["Parlor_Id"];
          this.staffAttendanceUpdateRequest.upStaffAttendance.Staff_Id = val["Staff_Id"];
          this.staffAttendanceUpdateRequest.upStaffAttendance.Presenty_Status = val["Presenty_Status"];
          this.in_time = this.convertTime(val["In_Time"]);//val["In_Time"];
          this.out_time = this.convertTime(val["Out_Time"]);
        }

        // this.inTime= this.datepipe.transform(this.convertTime(val["In_Time"]), 'hh:mm a');
        this.outTime = val["Out_Time"];
      }
    })
  }

  calculateTime() {
    let duration = "";
    let hours = 0, min = 0;
    if (this.in_time != "" && this.out_time != "") {
      let inHoursMinutes = moment(this.in_time).format('HH:mm').split('T');
      console.log(inHoursMinutes);
      let inTime = inHoursMinutes[0].split(':');
      let inTimeH = inTime[0];
      let inTimeM = inTime[1];

      let outHoursMinutes = moment(this.out_time).format('HH:mm').split('T');
      console.log(inHoursMinutes);
      let outTime = outHoursMinutes[0].split(':');
      let outTimeH = outTime[0];
      let outTimeM = outTime[1];

      if (inTimeH == outTimeH) {
        if (inTimeM > outTimeM) {
          this.isTimeValid = true;
          this.toast.show("outTime always greater than inTime")
        }
        else {
          hours = parseInt(outTimeH) - parseInt(inTimeH)
          min = parseInt(outTimeM) - parseInt(inTimeM)
          this.isTimeValid = false;
        }
      }
      else if (inTimeH > outTimeH) {
        this.toast.show("outTime always greater than inTime")
        this.isTimeValid = true;
      }
      else {
        hours = parseInt(outTimeH) - parseInt(inTimeH)
        min = parseInt(outTimeM) - parseInt(inTimeM)
        this.isTimeValid = false;
      }
      duration = hours + " hrs " + min + " min";
      this.workingHours = duration

      console.log(inTime);
    }

  }

  async updateAttendance() {
    debugger;
    this.load = await this.loadingController.create({ spinner: 'bubbles', message: 'wait a moment' })
    this.load.present();

    let inHoursMinutes = moment(this.in_time).format('HH:mmz').split('T');
    let inTime = this.formatAMPM(inHoursMinutes[0].split(':'));


    let outHoursMinutes = moment(this.out_time).format('HH:mmz').split('T');
    let outTIme = this.formatAMPM(outHoursMinutes[0].split(':'));


    if (this.staffAttendanceUpdateRequest.upStaffAttendance.Staff_Attendance_Id === 0) {
      this.in_time = inTime;
      this.out_time = outTIme;
      console.log(this.staffAttendanceNewRequest);
      this.staffAttendanceNewRequest.newStaffAttendance.In_Time = this.in_time;
      this.staffAttendanceNewRequest.newStaffAttendance.Out_Time = this.out_time;
      this.restService.newStaffInOutTime(this.staffAttendanceNewRequest).then((data) => {
        //this.load.present1();
        this.staffAttendanceNewResponse = data;
        console.log(data)
        if (this.staffAttendanceNewResponse.SaveStaffSingleAttendanceResult.Result == "Success") {
          this.load.dismiss();
          this.toast.show("Staff attendance updated successfully");
          this.modalController.dismiss();
        } else {
          //this.load.dismiss();
          this.toast.show("Fail to update staff attendance");
        }
      });
    }
    else {
      this.in_time = inTime;
      this.out_time = outTIme;
      console.log(this.staffAttendanceUpdateRequest);
      this.staffAttendanceUpdateRequest.upStaffAttendance.In_Time = this.in_time;
      this.staffAttendanceUpdateRequest.upStaffAttendance.Out_Time = this.out_time;
      this.restService.updateStaffInOutTime(this.staffAttendanceUpdateRequest).then((data) => {
        //this.load.present1();
        this.staffAttendanceUpdateResponse = data;
        console.log(data)
        if (this.staffAttendanceUpdateResponse.UpdateStaffSingleAttendanceResult.Result == "Success") {
          this.load.dismiss();
          this.toast.show("Staff attendance updated successfully");

          this.modalController.dismiss();
        } else {
          // this.load.dismiss();
          this.toast.show("Fail to update staff attendance");
        }
      });
    }



  }

  convertTime(Strtime) {
    if (Strtime != null && Strtime != "" ) {
      var time = Strtime;
      var hours = Number(time.match(/^(\d+)/)[1]);
      this.workingHours = hours;
      var minutes = Number(time.match(/:(\d+)/)[1]);
      //this.workingMin = minutes;

      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      //alert(sHours + ":" + sMinutes);
      //2020-12-09T16:12:04.421+05:30
      //2020-12-09T15:37:15.421+05:30
      let validtime = "2020-12-09T" + sHours + ":" + sMinutes + ":15.421+05:30";
      return new Date(validtime).toISOString();
    }

  }

  formatAMPM(date) {
    // debugger;
    var hours = date[0];
    var minutes = date[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  close() {
    this.modalController.dismiss();
  }

}


//"2022-05-16T18:45:35.365+05:30"

// {
//   "UpdateStaffSingleAttendanceResult": {
//       "DatabaseError": null,
//       "Message": null,
//       "Record_Id": "19",
//       "Record_Id_Name": "Staff_Id",
//       "Result": "Success",
//       "SMS_Status": null,
//       "WhatsApp_Status": null
//   }
// }