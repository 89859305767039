import { FilterPageModule } from './pages/filter/filter.module';
import { BookingSuccessfullyPageModule } from './pages/booking-successfully/booking-successfully.module';
import { CodeSendModalPageModule } from './pages/code-send-modal/code-send-modal.module';
import { ImageModalPageModule } from './pages/image-modal/image-modal.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';
import { EditadvancePageModule } from './pages/editadvance/editadvance.module';
import { CollectiondetailPageModule } from './pages/collectiondetail/collectiondetail.module';
import{StaffdetailsPageModule} from './pages/staffdetails/staffdetails.module';
import{SupplierdetailsPageModule} from './pages/supplierdetails/supplierdetails.module';
import { EnquirydetailPageModule } from './pages/enquirydetail/enquirydetail.module';
import { AnalysisAppointmentDetailsPageModule } from './pages/analysis-appointment-details/analysis-appointment-details.module';
import { AnalysisServicesDetailsPageModule } from './pages/analysis-services-details/analysis-services-details.module';
import { BasicStaffAttendanceUpdatePageModule } from './pages/basic-staff-attendance-update/basic-staff-attendance-update.module';
import { ServiceStaffAssignPopupPageModule } from './pages/service-staff-assign-popup/service-staff-assign-popup.module';
import { RestService } from './rest.service';

import { ParlorService } from './pages/parlor.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LoginService } from '../app/pages/login.service';
import {NotificationsService} from '../app/pages/notifications.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FcmService } from '../app/pages/fcm.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NearSalonListPageModule } from './pages/near-salon-list/near-salon-list.module';
import { SharePageModule } from './pages/share/share.module';
import { EnableLocationPageModule } from './pages/enable-location/enable-location.module';
import { DatePipe } from '@angular/common';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { TranslateService,TranslateModule } from '@ngx-translate/core';
//import { Firebase } from '@ionic-native/firebase/ngx';
// import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/v4";
//import { FCM } from "@cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Crop } from '@ionic-native/crop/ngx';
import{ FCM } from '@ionic-native/fcm/ngx';
import { IonicRatingModule } from 'ionic4-rating';
import { StarRatingModule } from 'ionic4-star-rating';
import { from } from 'rxjs';
import {ComponentsModule} from './pages/component/components.module';
import { Network } from '@ionic-native/network/ngx';

import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { NgCalendarModule  } from 'ionic2-calendar';
import { Calendar } from '@ionic-native/calendar';
import { Ionic4DatepickerModule } from "@logisticinfotech/ionic4-datepicker";
import { DatePicker } from '@ionic-native/date-picker/ngx';

const firebase = {
  // your firebase web config
 }
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    ComponentsModule,
    NgCalendarModule,
   
    IonicModule.forRoot(),
    AppRoutingModule,
    StarRatingModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicRatingModule,
    SupplierdetailsPageModule,
    FormsModule,
    CollectiondetailPageModule,
    StaffdetailsPageModule,
    EditadvancePageModule,
    TranslateModule.forRoot(),
    ImageModalPageModule,
    CodeSendModalPageModule,
    BookingSuccessfullyPageModule,
    NearSalonListPageModule,
    SharePageModule,
    EnquirydetailPageModule,
    AnalysisAppointmentDetailsPageModule,
    AnalysisServicesDetailsPageModule,
    BasicStaffAttendanceUpdatePageModule,
    ServiceStaffAssignPopupPageModule,
    EnableLocationPageModule,
    FilterPageModule,
    Ionic4DatepickerModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ParlorService,
    CallNumber,
    DatePipe,
    FCM,
   DatePicker,
    FcmService,
    TranslateService,
    SocialSharing,
    RestService,
    NotificationsService,
    Camera,
    File,
    Network,
    LoginService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    Geolocation ,
    LocalNotifications,
    ImagePicker,
    Crop,
    PhotoViewer,
    FileTransferObject,
    FileTransfer,
    FileTransferObject,
    File,
    Camera,
    SMS 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
