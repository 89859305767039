import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-staffdetails',
  templateUrl: './staffdetails.page.html',
  styleUrls: ['./staffdetails.page.scss'],
})
export class StaffdetailsPage implements OnInit {
  Mobile_No: any;
  Salary: any;
  Holiday: any;
  Full_Address: any;
  staff: any;
  Staff_Name: any;
  Joining_Date: any;
  In_Time: any;
  Out_Time: any;
  staffedit: any;
  Pending_Advance: any;
  Pending_Commission: any;
  Daily_Working_Hours: any;
  salary_per_day=0;
  salary_per_hour=0;
  constructor(public modalController: ModalController, public datepipe: DatePipe, public load: LaodlingService, private toast: ToastService, private storage: Storage, private parlor: ParlorService, private navCtrl: NavController, private rest: RestService) {
    this.getdetail();

  }

  ngOnInit() {
  }


  // Aadhar_No: "4204204204"
  // Active_Status: "True"
  // Address: "dhagat"
  // City: "tarokeshahar"
  // District: "shani"
  // Email_Id: "dhagat@gmail.com"
  // Experience: "ajibat nahi"
  // Expertise: "kashyat nahi"
  // First_Name: "akon"
  // Full_Address: "dhagat, tarokeshahar, shani, Assam, 415415"
  // Gender: "Male"
  // In_Time: "06:30 PM"
  // Joining_Date: "03/11/2020"
  // Last_Name: "disuza"
  // Mobile_No: "4204204204"
  // Moddle_Name: null
  // Out_Time: "10:00 AM"
  // PAN_No: "420420"
  // Parlor_Id: "10"
  // Photo_URL: ""
  // Pin_Code: "415415"
  // Result: "Success"
  // Staff_Code: "Ep01"
  // Staff_Id: "4"
  // Staff_Name: "akon  disuza"
  // State: null




  getdetail() {
    this.load.present1();
    return this.storage.get('staff_detail').then((val) => {
      this.load.present1();
      this.staff = val;
      this.Staff_Name = this.staff.Staff_Name;
      this.Full_Address = this.staff.City;
      this.Joining_Date = this.staff.Joining_Date;
      this.In_Time = this.staff.In_Time;
      this.Out_Time = this.staff.Out_Time;
      this.Pending_Advance = this.staff.Pending_Advance;
      this.Pending_Commission = this.staff.Pending_Commission;
      this.Mobile_No = this.staff.Mobile_No;
      this.Daily_Working_Hours = this.staff.Daily_Working_Hours_String;
      this.Holiday = this.staff.Weekly_Off;
      this.salary_per_day=this.staff.Salary_Per_Day;
      this.salary_per_hour=this.staff.Salary_Per_Hour;
      console.log("staff:", this.staff);
    })
  }
  edit() {
    debugger;
    this.storage.set('staff_edit', 1)
    let data:any;
    data = {
      staffId:this.staff.Staff_Id,
      action: 1,
      };
    this.storage.set('staffAction', data)
    this.modalController.dismiss();
    //this.navCtrl.navigateForward('/createstaff');
    this.navCtrl.navigateForward('/basic-staff-create');


  }
  close() {
    this.modalController.dismiss();
  }


}
