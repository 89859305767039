import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { LoadingController } from '@ionic/angular';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-analysis-appointment-details',
  templateUrl: './analysis-appointment-details.page.html',
  styleUrls: ['./analysis-appointment-details.page.scss'],
})
export class AnalysisAppointmentDetailsPage implements OnInit {

  requestAppointmentDetail = {
    Appointment_Entry_Id: 0,
  }

 appointmentDetailResponse: any;

  storageItem:any;
  appointmentId:any;
  appointmentDate:any;
  appointmentTime:any;
  totalAmount:any;
  discountAmount:any;
  netAmount:any;
  appointmentListData:any[]=[];
  load:any;

  constructor( 
    public modalController: ModalController,
    public datepipe: DatePipe,
    public loadingservices :LaodlingService,
    public loadingController: LoadingController,
    private toast:ToastService, 
    private storage:Storage, 
    private parlor:ParlorService,
    private navCtrl: NavController,
    private restService:RestService) {
    }
  

    ngOnInit() {
      this.storage.get('appointmentDetail').then((val) => {
       // debugger;
          this.storageItem=val;
          console.log(this.storageItem);
          this.appointmentId=this.storageItem.Appointment_Entry_Id;
          this.appointmentListData=this.storageItem.Appointment_Service_List;
            this.appointmentDate=this.storageItem.Appointment_Date;
            this.appointmentTime=this.storageItem.Appointment_Time;
            this.totalAmount=this.storageItem.Total_Amount;
         // this.requestAppointmentDetail.Appointment_Entry_Id=this.appointmentId;
         // this.getClientServicesData();
      });
    }

  async getClientServicesData() {
    //debugger;

      this.appointmentListData=[];
      this.load = await this.loadingController.create({
        spinner: 'bubbles',
        message: 'wait a moment'
      })
      this.load.present();
      this.restService.getClientServicesDetails(this.requestAppointmentDetail).then(data => {
          this.appointmentDetailResponse = data;
          console.log(this.appointmentDetailResponse);
            this.appointmentListData=this.appointmentDetailResponse.getAppointmentByIDResult.Appointment_Service_List;
            this.appointmentDate=this.appointmentDetailResponse.getAppointmentByIDResult.Appointment_Date;
            this.appointmentTime=this.appointmentDetailResponse.getAppointmentByIDResult.Appointment_Time;
            this.totalAmount=this.appointmentDetailResponse.getAppointmentByIDResult.Total_Amount;
            this.load.dismiss();
      });
  }


  close()
  {
    this.modalController.dismiss();
  }
}

