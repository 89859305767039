import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { RestService } from '../../rest.service';
import { ParlorService } from '../parlor.service';
import { Storage } from '@ionic/storage';
import { ToastService } from '../toast.service';
import { LaodlingService } from '../laodling.service';
import { DatePipe } from '@angular/common';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { LoginService } from '../login.service';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
@Component({
  selector: 'app-enquirydetail',
  templateUrl: './enquirydetail.page.html',
  styleUrls: ['./enquirydetail.page.scss'],
})
export class EnquirydetailPage implements OnInit {
  enquiry: any;
  clientname: any;
  mobileno: any;
  dob: any;
  shareMessage: any;
  profileData: any;
  parlorInfo: any;
  address: any;
  bannerImageURL: any;
  planId: any;
  parlorId: any;
  bookingLink: any;
  enquiryReminderMessage: any;
  services: any[] = [];
  total_amount: any;
  requestProfileData = {
    Parlor_Id: ''
  }
  responsedata = {
    mobile_no: '',
    name: '',
    client_id: ''

  }
  constructor(private modalController: ModalController, private loginervice: LoginService, private socialSharing: SocialSharing, private callNumber: CallNumber, public datepipe: DatePipe, public load: LaodlingService, private toast: ToastService, private storage: Storage, private parlor: ParlorService, private navCtrl: NavController, private rest: RestService) {
    this.loginervice.load();
    this.requestProfileData.Parlor_Id = this.loginervice.login.Parlor_Id;
    this.parlorInfo = "*" + this.loginervice.login.Parlor_Name + "*\n" + this.loginervice.login.Mobile_No;
    this.parlorId = this.loginervice.login.Parlor_Id;
    this.enquiryReminderMessage = this.loginervice.login.enquiryReminder;
    debugger;
    this.getdetail();
    this.getProfileData();
  }

  getProfileData() {
    this.rest.getProfileData(this.requestProfileData).then(data => {
      this.profileData = data;
      this.address = this.profileData.getProfileDataResult.Address;
      this.planId = this.profileData.getProfileDataResult.Plan_Id;
      this.bookingLink = this.planId == 7 ? "" : " Visit for online booking -" + this.profileData.getProfileDataResult.OnlineBookingLink;
      if (this.profileData.getProfileDataResult.is_Banner_Exist=="True") {
        this.bannerImageURL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner" + this.parlorId + ".jpg";
      }
      else {
        this.bannerImageURL = "https://beautyImageApi.preshitcreations.com/Published/beauty_banner_photos/" + "ParlourBanner.jpg";
      }
    })
  }

  ngOnInit() {
  }

  getdetail() {
    this.load.present1();
    return this.storage.get('enquiry_detail').then((val) => {
      // Client_Data:
      // Address: "a/p-Kurundwad"
      // Anniversary_Date: "1/26/2020 12:00:00 AM"
      // Appointment_Master_List: []
      // BillAmount: null
      // Bill_Entry_List: []
      // Birth_Date: "1/26/2020 12:00:00 AM"
      // ClientClassification: null
      // Client_Code: "Abhi"
      // Client_Id: "8"
      // Client_Name: "Abhilash Gurav"
      // Customer_Category: null
      // DaysOfLastVisit: null
      // Email: "abhilashgurav7385@gmail.com"
      // Last_Visited_Date: null
      // Mobile_No: "8600631794"
      // NoOfVisits: null
      // Parlor_Id: "4"
      // Photo_URL: null
      // Result: null
      // __proto__: Object
      // Client_Id: "8"
      // Enquiry_Date: "01/02/2020"
      // Enquiry_Id: "1002"
      // Enquiry_Service_List: [{…}]
      // Mobile_No: null
      // Parlor_Id: null
      // Result: "Success"
      // Total_Amount: "150.00"
      this.enquiry = val;
      this.responsedata.client_id = this.enquiry.Client_Data.Client_Id;
      this.responsedata.mobile_no = this.enquiry.Client_Data.Mobile_No;
      this.responsedata.name = this.enquiry.Client_Data.Client_Name;
      this.clientname = this.enquiry.Client_Data.Client_Name;
      this.mobileno = this.enquiry.Client_Data.Mobile_No;
      this.dob = this.enquiry.Enquiry_Date;
      this.services = this.enquiry.Enquiry_Service_List;
      this.total_amount = this.enquiry.Total_Amount;
      console.log(this.enquiry);
      this.load.present1();
    })
  }


  call() {
    this.callNumber.callNumber(this.mobileno, true)
      .then(res => console.log('Launched dialer!', res))
      .catch(err => console.log('Error launching dialer', err));
  }

  SMS() {
    this.storage.set('SmsOffer', this.responsedata);
    this.navCtrl.navigateForward('/sendoffers');
    this.modalController.dismiss();
  }

  Share() {
    let serviceItem = "";
    for (let item of this.services) {
      serviceItem = serviceItem + item.Service_Name + ",";
    }
    serviceItem = serviceItem.substring(0, serviceItem.length - 1);

    var message = this.enquiryReminderMessage;
    // this.shareMessage = message.replace("#Address#", this.profileData.getProfileDataResult.Address)
    // .replace("#ClientName#", this.enquiry.Client_Data.Client_Name)
    // .replace("#enquiryDate#", this.dob)
    // .replace("#serviceItem#", serviceItem)
    // .replace("#OnlineBookingLink#", this.bookingLink);

    this.shareMessage = this.parlorInfo + "\nDear " + this.enquiry.Client_Data.Client_Name +
      ", this is a reminder about your enquiry on " + this.dob + " about " + serviceItem
      + ". Looking forward to seeing you. Visit for online booking -"
      + this.profileData.getProfileDataResult.OnlineBookingLink;
    console.log(this.shareMessage);
    debugger;
    // this.socialSharing
    //   .share(this.shareMessage, "Enquiry Reminder", this.bannerImageURL, null)
    //   .then(() => { }).catch(() => { });

      this.socialSharing
      .shareViaWhatsAppToReceiver('+91'+this.enquiry.Client_Data.Mobile_No,this.shareMessage,this.bannerImageURL,null)
      .then(() => {}).catch(() => {});
    this.modalController.dismiss();
  }

  close() {
    this.modalController.dismiss();
  }
}
